<template>
  <div>
    <div>
      <b-row>
        <b-col md="2">
          <b-form-group label="Category" label-for="category">
            <b-form-select v-model="filter.category" :options="category_options" />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Variant" label-for="variant">
            <b-form-select v-model="filter.variant" :options="itemVariant" />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Tier" label-for="tier">
            <b-form-select v-model="filter.tier" :options="itemLevel" />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Avatar" label-for="avatar">
            <b-form-select v-model="filter.character_type" :options="avatarOptions" />
          </b-form-group>
        </b-col>
        <b-col md="2" class="pr-0">
          <b-form-group label="Default item?">
            <b-checkbox v-model="filter.default" />
          </b-form-group>
        </b-col>
        <b-col md="2" class="pl-0">
          <div class="d-flex pt-1">
            <div class="pr-1" style="height: 50px">
              <b-button @click="search" variant="primary">
                Search
              </b-button>
            </div>
            <div style="height: 50px">
              <b-button @click="clearFilter" variant="warning">
                Clear
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table-simple caption-top responsive bordered>
      <b-thead head-variant="light">
        <b-tr>
          <b-th class="text-center">STT</b-th>
          <b-th class="text-center">Thumbnail</b-th>
          <b-th class="text-center">Name</b-th>
          <b-th class="text-center">Category</b-th>
          <b-th class="text-center">Type</b-th>
          <b-th class="text-center">Variant</b-th>
          <b-th class="text-center">Tier</b-th>
          <b-th class="text-center">Avatar Type</b-th>
          <b-th class="text-center">Action</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="(item, index) in items">
          <b-tr :key="item._id || index">
            <b-th class="text-center">{{ index + 1 }}</b-th>
            <b-th class="text-center">
              <b-card-img v-if="item.thumbnail.value" class="item-thumbnail" :src="item.thumbnail.value" alt="item-thumbnail" />
            </b-th>
            <b-th class="text-center">
              <!-- <ui-component :component-data="item.name" /> -->
              <render-translatable-text :component-data="item.name" />
            </b-th>
            <b-th class="text-center"> {{ item.category }}</b-th>
            <b-th class="text-center"> {{ convertItemTypeName(item.type) }}</b-th>
            <b-th class="text-center"> {{ convertVariantName(item.type,item.variant,item.tier).variant.text }}</b-th>
            <b-th class="text-center"> {{ convertVariantName(item.type,item.variant,item.tier).tier.text }}</b-th>
            <b-th class="text-center"> {{ convertAvatarType(item.character_type) }} </b-th>
            <b-th class="text-center">
              <div class="d-flex justify-content-center">
                <b-button
                variant="gradient-warning"
                style="margin-right: 5px"
                class="btn-icon"
                @click="editItem(item._id)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                @click="confirmDelete(item._id)"
                variant="gradient-danger"
                class="btn-icon"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
              </div>
            </b-th>
          </b-tr>
          
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import service from "../../service";
import config from "../../config";
export default {
  props: {
    items: { type: Array, required: true },
  },
  data(){
    return{
      filter:{
        type: "EQUIPMENT"
      },
      type_options:[
        // {text: "All", value: null},
        {text: "Clothes", value: "EQUIPMENT"},
        // {text: "Smoothies", value: "CONSUMABLE"},
        // {text: "Box", value: "BOX"},
      ],
      category_options:[
        {text: "Shirt", value: "SHIRT"},
        {text: "Trousers", value: "TROUSERS"},
        {text: "Shoes", value: "SHOES"},
      ],
      avatarOptions:[
        {text: "Male", value: 1},
        {text: "Female", value: 2},
      ]
    }
  },
  computed:{
    itemVariant(){
      if (this.filter.type == "CONSUMABLE"){
        return config.SMOOTHY
      }
      if (this.filter.type == "EQUIPMENT"){
        return config.EQUIPMENT
      }
      if (this.filter.type == "BOX"){
        return null
      }
      return null
    },
    itemLevel(){
      if (this.itemVariant && this.filter.variant){
        let variant = this.itemVariant.find((x)=>{
          return x.value == this.filter.variant
        })
        return variant ? variant.tier : null
      }
      return null
    }
  },
  methods: {
    confirmDelete(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteItem(id);
          }
        });
    },
    async deleteItem(id) {
      await service.delete({ id: id });
      this.$emit("getList");
    },
    editItem(id) {
      this.$router.push({
        name: "item-detail",
        params: { item_id: id },
      });
    },
    convertItemTypeName(value){
      if(value == "EQUIPMENT"){
        return "CLOTHES"
      }
      else if(value == "CONSUMABLE"){
        return "SMOOTHY"
      }
      else{
        return value
      }
    },
    convertVariantName(type, variant, tier){
      return config.convertVariantName(type, variant, tier)
    },
    convertAvatarType(type){
      if(type == 1){
        return "Male"
      }
      else if (type == 2){
        return "Female"
      }
      return ""
    },
    search(){
      for (var propName in this.filter) {
        if (this.filter[propName] === null || this.filter[propName] === undefined) {
          delete this.filter[propName];
        }
      }
      this.$emit("getList", this.filter)
    },
    clearFilter(){
      this.$emit("clearFilter")
    }
  },
};
</script>
<style scoped>
  .item-thumbnail{
    max-width: 50px;
  }
  .table th{
    padding: 0.72rem 1rem;
  }
</style>
