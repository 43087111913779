export default {
  thumbnail:{
    type: "image",
    value: null,
    meta:{
      alt: null,
      redirect_link: null
    },
  },
  name: {
    meta: {
      default_lang: "en"
    },
    type: "translatable-text",
    value: null,
    translations:[{
      language: "vi",
      value: null
    }]
  },
  description: {
    meta: {
      default_lang: "en"
    },
    type: "translatable-text",
    value: null,
    translations:[{
      language: "vi",
      value: null
    }]
  },
  type: "EQUIPMENT",
  category: "OTHER",
  variant: null,
  tier: null,
  sprite_url: null,
  set_id: null,
  character_type: null,
}