module.exports = {
  EQUIPMENT:[
    {
      text: "White",
      value: 1,
      tier: [
        {
          text:"1",
          value: 1
        },
        {
          text:"2",
          value: 2
        },
        {
          text:"3",
          value: 3
        }
      ]
    },
    {
      text: "Green",
      value: 2,
      tier: [
        {
          text:"1",
          value: 1
        },
        {
          text:"2",
          value: 2
        },
        {
          text:"3",
          value: 3
        }
      ]
    },
    {
      text: "Orange",
      value: 3,
      tier: [
        {
          text:"1",
          value: 1
        },
        {
          text:"2",
          value: 2
        },
      ]
    },
    {
      text: "Purple",
      value: 4,
      tier: [
        {
          text:"1",
          value: 1
        },
      ]
    }
  ],
  SMOOTHY:[
    {
      text: "Red",
      value: 1,
      tier: [
        {
          text:"Mini-size",
          value: 1
        },
        {
          text:"Medium size",
          value: 2
        },
        {
          text:"Large size",
          value: 3
        },
        {
          text:"Extra large size",
          value: 4
        },
      ]
    },
    {
      text: "Yellow",
      value: 2,
      tier: [
        {
          text:"Mini-size",
          value: 1
        },
        {
          text:"Medium size",
          value: 2
        },
        {
          text:"Large size",
          value: 3
        },
        {
          text:"Extra large size",
          value: 4
        },
      ]
    },
    {
      text: "Green",
      value: 3,
      tier: [
        {
          text:"Mini-size",
          value: 1
        },
        {
          text:"Medium size",
          value: 2
        },
        {
          text:"Large size",
          value: 3
        },
        {
          text:"Extra large size",
          value: 4
        },
      ]
    },
  ],
  TOKEN:[
    {
      text: "Amount 5",
      value: 1
    },
    {
      text: "Amount 10",
      value: 2
    },
    {
      text: "Amount 15",
      value: 3
    },
    {
      text: "Amount 20",
      value: 4
    },{
      text: "Amount 25",
      value: 5
    }
  ],
  convertVariantName(type, variant, tier){
    let config = null;
    switch(type){
      case "EQUIPMENT":
        config = this.EQUIPMENT;
        break
      case "CONSUMABLE":
        config = this.SMOOTHY;
        break
      default:
        return {variant: {text: "", value: ""}, tier: {text: "", value: ""}}
    }
    // if (type == "EQUIPMENT"){
    //   config = this.EQUIPMENT;
    // }
    // else if (type == "CONSUMABLE"){
    //   config = this.SMOOTHY;
    // }
    // else {
    //   return{variant: {text: "", value: ""}, tier: {text: "", value: ""}}
    // }
    let tierName = null;
    let variantName = config.find((x)=>{
      if (x.value == variant){
        tierName = x.tier.find((x)=>{
          return x.value == tier
        })
      }
      return x.value == variant 
    })
    return {
      variant: variantName,
      tier: tierName
    }
  }
}