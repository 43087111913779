<template>
  <div>
    <b-button variant="primary" @click="isModalShow=true">
    <b-spinner v-if="isLoading" small class="mr-50" />
    <feather-icon v-else icon="PlusIcon" class="mr-50" />
    <span class="align-middle">Add New</span>
    </b-button>
    <b-modal
      v-model="isModalShow"
      title="Create new item"
      hide-footer
      size="lg" modal-class="modal-primary"
      no-close-on-backdrop="false"
    >
      <validation-observer
        ref="createForm"
        v-slot="{ handleSubmit }"
      >
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
          <b-form-group
            label="Thumbnail"
            label-for="h-item-thumbnail"
          >
            <ui-component :component-data="itemData.thumbnail" class="border" />
          </b-form-group>
          <validation-provider
            name="Name"
            v-slot="validationContext"
            :rules="{ required: true}"
          >
            <b-form-group
              label="Item Name"
              label-for="h-item-name"
            >
              <b-form-input
                v-model="itemData.name.translations[0].value"
                id="item-name"
                name="item-name"
                type="text"
                :state="getValidationState(validationContext)"
                aria-describedby="input-name-feedback"
              />
              <b-form-invalid-feedback id="input-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group
            label="Item Description"
            label-for="h-item-description"
          >
            <b-form-input
              v-model="itemData.description.translations[0].value"
              id="item-description"
              name="item-description"
              type="text"
            />
          </b-form-group>
          <!-- <validation-provider
            name="Type"
            v-slot="validationContext"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Item Type"
              label-for="h-item-type"
            >
              <b-form-select 
                v-model="itemData.type"
                :options="types_options"
                :state="getValidationState(validationContext)"
                aria-describedby="input-type-feedback"
              />
              <b-form-invalid-feedback id="input-type-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->
          <validation-provider
            v-if="itemData.type == 'EQUIPMENT'"
            name="Category"
            v-slot="validationContext"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Category"
              label-for="h-category"
            >
              <b-form-select 
                v-model="itemData.category"
                :options="category_options"
                :state="getValidationState(validationContext)"
                aria-describedby="input-category-feedback" 
              />
            </b-form-group>
            <b-form-invalid-feedback id="input-category-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </validation-provider>
          <validation-provider
            name="Variant"
            v-slot="validationContext"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Item Variant"
              label-for="h-item-variant"
            >
              <b-form-select 
                v-model="itemData.variant" 
                :options="itemVariant" 
                :state="getValidationState(validationContext)"
                aria-describedby="input-variant-feedback"
              />
              <b-form-invalid-feedback id="input-variant-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            name="Tier"
            v-slot="validationContext"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Item Tier"
              label-for="h-item-tier"
            >
              <b-form-select 
                v-model="itemData.tier" 
                :options="itemLevel" 
                :state="getValidationState(validationContext)"
                aria-describedby="input-tier-feedback"
              />
              <b-form-invalid-feedback id="input-tier-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            v-if="itemData.type == 'EQUIPMENT'"
            name="Avatar Type"
            v-slot="validationContext"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Avatar type"
              label-for="h-item-tier"
            >
              <b-form-select 
                v-model="itemData.character_type" 
                :options="avatar_options" 
                :state="getValidationState(validationContext)"
                aria-describedby="input-avatar-feedback"
              />
              <b-form-invalid-feedback id="input-avatar-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group v-if="itemData.type == 'EQUIPMENT'" label="Set item">
            <vue-select class="vue-select1" name="select1" :options="setItems" v-model="itemData.set_id" label="label" :reduce="label => label.value" :searchable="true" />
          </b-form-group>
          <b-form-group
            v-if="itemData.type == 'EQUIPMENT'"
            label="Sprite url"
            label-for="h-item-sprite-url"
          >
            <b-form-input
              v-model="itemData.sprite_url"
              id="item-sprite-url"
              name="item-sprite-url"
              type="text"
            />
          </b-form-group>
          <div class="d-flex">
            <label for="default-item">Default item</label>
            <b-checkbox
              class="ml-1"
              v-model="itemData.default"
              id="default-item"
              name="default-item"
              type="text"
            />
          </div>
        </b-form>
        
        <div class="d-flex justify-content-between my-2">
          <b-button variant="danger" @click="cancel">Cancel</b-button>
          <b-button type="submit" variant="primary" @click="create">Create</b-button>
        </div>
      </validation-observer>
      
      <hr>
      
    </b-modal>
  </div>
</template>

<script>
import service from "../../service";
import config from "../../config";
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import setService from "../../../item_sets/service"
import VueSelect from 'vue-select'
import defaultObject from '../../defaultObject'
export default {
  components:{
    ValidationProvider,
    ValidationObserver,
    VueSelect
  },
  data(){
    return{
      isLoading: false,
      isModalShow: false,
      itemData: JSON.parse(JSON.stringify(defaultObject)),
      category_options:[
        {text: "Shirt", value: "SHIRT"},
        {text: "Trousers", value: "TROUSERS"},
        {text: "Shoes", value: "SHOES"},
      ],
      types_options:[
        // {text: "Smoothies", value: "CONSUMABLE"},
        {text: "Clothes", value: "EQUIPMENT"},
        // {text: "Box", value: "BOX"},
      ],
      avatar_options:[
        {text: "Male", value: 1},
        {text: "Female", value: 2}
      ],
      setItems: [{label: "None", value: null}],
      set_id: null,
    }
  },
  computed:{
    itemVariant(){
      if (this.itemData.type == "CONSUMABLE"){
        return config.SMOOTHY
      }
      if (this.itemData.type == "EQUIPMENT"){
        return config.EQUIPMENT
      }
      if (this.itemData.type == "BOX"){
        return null
      }
      return null
    },
    itemLevel(){
      if (this.itemVariant && this.itemData.variant){
        let variant = this.itemVariant.find((x)=>{
          return x.value == this.itemData.variant
        })
        return variant ? variant.tier : null
      }
      return null
    }
  },
  created(){
    this.getSetItem()
  },
  methods: {
    async create() {
      let validation = await this.$refs.createForm.validate();
      if (validation){
        this.isLoading = true;
        let new_item = await service.create(JSON.stringify(this.itemData));
        this.isLoading = false;
        if (new_item) {
          this.$store.dispatch('pushSuccessNotify', { text: 'Item created!' })
          this.$emit("create");
          this.cancel();
        }
      }
    },
    cancel(){
      this.isModalShow = false;
      this.itemData = JSON.parse(JSON.stringify(defaultObject));
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async getSetItem(){
      let data = await setService.getList({
        page: 1,
        limit: 100
      })
      for (let i of data.list){
        let name = i.name.translations.find((x)=>{
          return x.language == this.$store.getters["auth/language"]
        });
        this.setItems.push({label: name ? name.value : i.name.translations[0].value, value: i._id})
      }
    },
  }
}
</script>